/* styles.css */

.logo-card-container {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: radial-gradient(
    58% 60% at 80% 80%,
    rgba(0, 255, 101, 0.07) 10%,
    rgba(255, 255, 255, 0.01) 100%
  );
  backdrop-filter: blur(3.6px);
  max-width: "auto";
  margin-top: 0;
  text-align: center;
  padding: 80px;
  margin-left: 0px;
  margin-right: auto;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .logo-card-container {
    padding: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 130px;
  }
}
