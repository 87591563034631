/* In your CSS file or in a styled-components styled component */

/* Show desktop background */
.bg-desktop {
  display: block;
}

/* Hide mobile background */
.bg-mobile {
  display: none;
}

/* Media query for mobile */
@media (max-width: 600px) {
  /* Show mobile background */
  .bg-desktop {
    display: none;
  }

  /* Hide desktop background */
  .bg-mobile {
    display: block;
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 2px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
