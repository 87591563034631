@font-face {
  font-family: "Gilroy-Bold"; /*Can be any text*/
  src: local("Gilroy-Bold"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular"; /*Can be any text*/
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium"; /*Can be any text*/
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
}
