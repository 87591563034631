/* PriceCard.css */

/* Initially hide the white check */
#my-img-white {
  display: none;
}

/* Initially show the green check */
#my-img {
  display: block;
}

/* Show the white check on hover */
#price-card-main {
  &:hover #my-img-white {
    display: block;
  }
  &:hover #my-img {
    display: none;
  }
}


/* Hide the green check on hover */

