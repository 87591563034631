/* In your CSS file or in a styled-components styled component */

/* Show desktop background */
.bg-desktop {
  display: block;
}

/* Hide mobile background */
.bg-mobile {
  display: none;
}

/* Media query for mobile */
@media (max-width: 600px) {
  /* Show mobile background */
  .bg-desktop {
    display: none;
  }

  /* Hide desktop background */
  .bg-mobile {
    display: block;
  }
}
