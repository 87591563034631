.searchbox-wrap {
  display: flex;
  width: 98%;
  /* margin-top: 8%; */
  /* margin-left: auto;
  margin-right: auto; */

  input {
    /* flex: 1; */
    flex-grow: 1;
    display: flex;
    padding: 12px 14px;
    /* font-size: 1.1em; */

    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-bottomleft: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--Gray-300, #d0d5dd);
  }

  button {
    /* padding-right: 10px; */

    background-color: #fff;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--Gray-300, #d0d5dd);
    border-left: none;
    cursor: pointer;
    cursor: hand;
    min-width: 70px;
  }
}
