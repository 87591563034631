/* MyComponent.css */
.myDiv {
  border-radius: 26px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(17.5px);
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 16px 17px;
  z-index: -1;
  width: auto;
  margin-left: 14%;
  margin-right: 14%;
  flex-direction: row;
}

/* Responsive styles for xs screens (e.g., < 600px) */
@media (max-width: 599px) {
  .myDiv {
    width: auto;
    margin-left: 3%;
    margin-right: 3%;
    flex-direction: column;
    padding: 0px 18px 15px 13px;
  }
}

/* Responsive styles for md screens (e.g., 600px - 959px) */
@media (min-width: 600px) and (max-width: 959px) {
  .myDiv {
    width: auto;
    margin-left: 14%;
    margin-right: 14%;
    flex-direction: row;
  }
}

.custom-button-card-div {
  border-radius: 26px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(3.5999999046325684px);
  width: auto;
  margin-left: 14%;
  margin-right: 14%;
  flex-direction: row;
}

/* Responsive styles for xs screens (e.g., < 600px) */
@media (max-width: 599px) {
  .custom-button-card-div {
    width: auto;
    margin-left: 3%;
    margin-right: 3%;
    flex-direction: column;
  }
}

/* Responsive styles for md screens (e.g., 600px - 959px) */
@media (min-width: 600px) and (max-width: 959px) {
  .custom-button-card-div {
    width: auto;
    margin-left: 14%;
    margin-right: 14%;
    flex-direction: row;
  }
}
